export const january = [
  {
    title: "Semaine du 13 janvier au 18 janvier 2025 ",
    list: [
      "Lasagne jambon de Parme et parmesan",
      "Ravioles viande de bœuf/pleurotes, sauce aux 3 fromages légèrement tomatée ",
    ]
  },
  {
    title: "Semaine du 20 janvier au 25 janvier  2025  ",
    list: [
      "Lasagne aux aubergines et San Daniele ",
      "Ravioles Ricotta de chèvre et pistaches à la crème de parmesan et pancetta ",
    ]
  },
  {
    title: "Semaine du 27 janvier au 01 février 2025  ",
    list: [
      "Osso Bucco",
      "Ravioles au saumon, poireaux, velouté au vin blanc ",
    ]
  },
]

export const february = [
  {
    title: "Semaine du 27 janvier au 01 février 2025  ",
    list: [
      "Osso Bucco",
      "Ravioles au saumon, poireaux, velouté au vin blanc ",
    ]
  },
  {
    title: "Semaine du 03 février au 08 février  2025   ",
    list: [
      "Melanzane alla parmigiana",
      "Ravioles à la viande - saucisse italienne et tomates cerises",
    ]
  },
  {
    title: "Semaine du 10 février au 15 février  2025 ",
    list: [
      "Ravioles al tartufo e stracchino",
      "Cuori di formaggio julienne de légumes ",
      "Balanzoni al Parmigiano Reggiano sauce Ragù",
    ]
  },
  {
    title: "Semaine du 17 février au 22 février  2025   ",
    list: [
      "Lasagne aux scampis et épinards ",
      "Ravioles aux funghi porcini  ",
    ]
  },
  {
    title: "Semaine du 19 février au 24 février 2024 ",
    list: [
      "Lasagne aux scampis et épinards (nouveauté)",
    ]
  },
  {
    title: "Semaine du 24 février au 01 mars 2025  ",
    list: [
      "Culurgiones aux tomates cerises et crème d'artichaut ",
      "Arancini",
    ]
  },
]

export const march = [
  {
    title: "Semaine du 24 février au 01 mars 2025  ",
    list: [
      "Culurgiones aux tomates cerises et crème d'artichaut ",
      "Arancini",
    ]
  },
  {
    title: "Semaine du 03 mars au 08 mars 2025 ",
    list: [
      "Ravioles épinards/ricotta - sauce aux cèpes",
      "Ravioles aux crustacés sauce safran ",
    ]
  },
  {
    title: "Semaine du 10 mars au 15 mars  2025 ",
    list: [
      "Panciotti aux aubergines sauce tomatée et pancetta",
      "Ravioles aux langoustes, billes de courgettes / Marsala, beurre aux herbes"
    ]
  },
  {
    title: "Semaine du 17 mars au 22 mars  2025  ",
    list: [
      "Ravioles burrata di Buffalla all'amatriciana et scampis ",
      "Fiocchi  aux poires et fromages sauce Gorgonzola et noix "
    ]
  },
  {
    title: "Semaine du 24 mars au 29 mars 2025  ",
    list: [
      "Cannelloni scampis/courgettes",
      "Ravioles brasato all'amatriciana "
    ]
  },
  {
    title: "Semaine du 31 mars au 05 avril 2025   ",
    list: [
      "Osso Bucco",
      "Ravioles aux asperges et mascarpone sauce tomate et crème basilic "
    ]
  },
]

export const april = [
  {
    title: "Semaine du 31 mars au 05 avril 2025   ",
    list: [
      "Osso Bucco",
      "Ravioles aux asperges et mascarpone sauce tomate et crème basilic "
    ]
  },
  {
    title: 'Semaine du 07 avril au 12 avril  2025  ',
    list: [
      "Cannelloni aux 2 saumons et aneth (nouveauté)",
    ]
  },
  {
    title: 'Semaine du 14 avril au 19 avril  2025 ',
    list: [
      "Melanzane alla parmigiana",
      "Panciotti aux St Jacques, tomates cerises,  crevettes grises et Prosecco  "
    ]
  },
]

export const may = [
  {
    title: 'Semaine du 29 avril au 4 mai 2024',
    list: [
      "Ravioles aux funghi porcini  ",
      "Ravioles au saumon, poireaux, velouté au vin blanc"
    ]
  },
  {
    title: "Semaine du 6 au 11 mai 2024",
    list: [
      "Cuori di formaggio julienne de légumes ",
      "Ravioles burrata di Buffalla all'amatriciana et scampis",
    ]
  },
  {
    title: "Semaine du 13 au 18 mai 2024",
    list: [
      "Cannelloni scampis/courgettes (NOUVEAUTÉ) ",
    ]
  },
  {
    title: "Semaine du 20 au 25 mai 2024",
    list: [
      "Ravioles al tartufo e stracchino",
      "Lasagne aux aubergines et San Daniele ",
    ]
  },
  {
    title: "Semaine du 27 mai au 1 juin 2024",
    list: [
      "Ravioles aux crustacés sauce safran ",
      "Ravioles à la viande - saucisse italienne et tomates cerises"
    ]
  },
]

export const june = [
  {
    title: "Semaine du 3 au 8 juin 2024",
    list: [
      "Lasagne aux scampis et épinards ",
      "Fiocchi  aux poires et fromages sauce Gorgonzola et noix "
    ]
  },
  {
    title: "Semaine du 10 au 15 juin 2024",
    list: [
      "Cannelloni aux 2 saumons et aneth (NOUVEAUTÉ) ",
    ]
  },
  {
    title: "Semaine du 17 au 22 juin 2024",
    list: [
      "Melanzane alla parmigiana",
      "Panciotti aux St Jacques, tomates cerises crevettes grises et Prosecco",
    ]
  },
  {
    title: "Semaine du 24 au 29 juin 2024",
    list: [
      "Ravioles épinards/ricotta  sauce 4 fromages ",
      "Panciotti aux aubergines  sauce tomatée et pancetta"
    ]
  },
]

export const july = [
  {
    title: "Semaine du 1 au 6 juillet 2024",
    list: [
      "Lasagne végétarienne",
      "Taglioni al nero di sepia e gambas"
    ]
  },
  {
    title: "Semaine du 8 juillet au 13 juillet 2024",
    list: [
      "Polpette ",
      "Balanzoni al Parmigiano Reggiano sauce Ragù",
      "Culurgiones (Gnocchi sardes) aux tomates cerises",
    ]
  },
  {
    title: "Semaine du 15 juillet au 20 juillet 2024",
    list: [
      "Ravioles aux scampis, sauce pomodoro al pesto verde e pesto rosso  ",
      "Arancini"
    ]
  },
  {
    title: "Semaine du 22 juillet au 27 juillet 2024",
    list: [
      "Cannelloni aux 2 saumons et aneth",
      "Ravioles Ricotta de chèvre et pistaches à la crème de parmesan et pancetta"
    ]
  },
  {
    title: "Semaine du 29 juillet au 3 août 2024",
    list: [
      "Lasagne aux aubergines et San Daniele ",
      "Ravioles burrata di Buffalla all'amatriciana et scampis"
    ]
  },
]

export const august = [
  {
    title: "Semaine du 29 juillet au 3 août 2024",
    list: [
      "Lasagne aux aubergines et San Daniele ",
      "Ravioles burrata di Buffalla all'amatriciana et scampis"
    ]
  },
  {
    title: "Semaine du 5 août au 10 août  2024",
    list: [
      "Ravioles aux langoustes,  billes de  courgettes,  Marsala, beurre aux herbes",
      "Ravioles à la viande - saucisse italienne et tomates cerises"
    ]
  },
  {
    title: "Semaine du 12 août au 17 août  2024",
    list: [
      "Lasagne jambon de Parme et parmesan",
      "Ravioles épinards/ricotta - sauce aux cèpes"
    ]
  },
  {
    title: "Semaine du 19 août au 24 août  2024",
    list: [
      "Ravioles aux crustacés sauce safran ",
      "Polpette ",
      "Panciotti aux aubergines sauce tomatée et pancetta ",
    ]
  },
  {
    title: "Semaine du 26 août au 31 août 2024",
    list: [
      "Melanzane alla parmigiana",
      "Ravioles aux asperges et mascarpone, sauce tomate et crème basilic"
    ]
  },
]

export const september = [
  {
    title: "Semaine du 02 au 07 septembre 2024",
    list: [
      "Taglioni al nero di sepia e gambas  ",
      "Fiocchi  aux poires et fromages sauce Gorgonzola et noix ",
    ]
  },
  {
    title: "Semaine du 09  au 14 septembre 2024",
    list: [
      "Ravioles al tartufo e stracchino",
      "Cannelloni scampis/courgettes "
    ]
  },
  {
    title: "Semaine du 16  au 21 septembre 2024",
    list: [
      "Ravioles aux scampis, sauce pomodoro al pesto verde e pesto rosso  ",
      "Ravioles Ricotta de chèvre et pistaches à la crème de parmesan et pancetta"
    ]
  },
  {
    title: "Semaine du 23 au 28 septembre 2024",
    list: [
      "Lasagne aux scampis et épinards ",
      "Ravioles aux funghi porcini  "
    ]
  },
  {
    title: "Semaine du 30 septembre au 05 octobre 2024",
    list: [
      "Osso Bucco",
      "Ravioles au saumon, poireaux, velouté au vin blanc "
    ]
  },
]

export const october = [
  {
    title: "Semaine du 30 septembre au 05 octobre 2024",
    list: [
      "Osso Bucco",
      "Ravioles au saumon, poireaux, velouté au vin blanc "
    ]
  },
  {
    title: "Semaine du 07 au 12 octobre 2024",
    list: [
      "Ravioles viande de bœuf/pleurotes, sauce aux 3 fromages légèrement tomatée. ",
      "Cannelloni aux 2 saumons et aneth"
    ]
  },
  {
    title: "Semaine du 14 au 19 octobre 2024",
    list: [
      "Balanzoni al Parmigiano Reggiano sauce Ragù",
      "Culurgiones aux tomates cerises et  crème d'artichaut "
    ]
  },
  {
    title: "Semaine du 21 au 26 octobre 2024",
    list: [
      "Arancini",
      "Lasagne jambon de Parme et parmesan "
    ]
  },
  {
    title: "Semaine du 28 oct au 02 novembre 2024",
    list: [
      "Ravioles aux asperges et mascarpone, sauce tomate et crème basilic",
      "Ravioles alla zucca con Amaretti "
    ]
  },
]

export const november = [
  {
    title: "Semaine du 28 oct au 02 novembre 2024",
    list: [
      "Ravioles aux asperges et mascarpone, sauce tomate et crème basilic",
      "Ravioles alla zucca con Amaretti "
    ]
  },
  {
    title: "Semaine du 04  au 09 novembre 2024",
    list: [
      "Melanzane alla parmigiana",
      "Panciotti aux St Jacques, tomates cerises, crevettes grises et Prosecco"
    ]
  },
  {
    title: "Semaine du 11 au 16 novembre 2024",
    list: [
      "Cannelloni scampis/courgettes",
      "Ravioles al tartufo e stracchino"
    ]
  },
  {
    title: "Semaine du 18 au 23 novembre 2024",
    list: [
      "Lasagne aux scampis et épinards ",
      "Ravioles à la viande - saucisse italienne et tomates cerises"
    ]
  },
  {
    title: "Semaine du 25 au 30 novembre 2024",
    list: [
      "Osso Bucco",
      "Fiocchi  aux poires et fromages sauce Gorgonzola et noix "
    ]
  },
]

export const december = [
  {
    title: "Semaine du 02 au 07 décembre 2024",
    list: [
      "Lasagne aux aubergines et San Daniele ",
      "Ravioles burrata di Buffalla all'amatriciana et scampis"
    ]
  },
  {
    title: "Semaine du 09 au 14 décembre 2024",
    list: [
      "Ravioles aux crustacés sauce safran ",
      "Ravioles épinards/ricotta sauce aux cèpes"
    ]
  },
  {
    title: "Semaine du 16 au 21 décembre 2024",
    list: [
      "Panciotti aux aubergines sauce tomatée et pancetta",
      "Ravioles aux langoustes,  billes de courgettes,  Marsala, beurre aux herbes"
    ]
  },
]
